<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            small
            v-on="{ ...dialog, ...tooltip }"
            style="position: absolute; right: 25px"
            color="primary"
            class="ml-2"
          >
            <v-icon small> $edit </v-icon>
          </v-btn>
        </template>
        {{ $t("routes.inventoryEdit") }}
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("routes.inventoryEdit") }}
      </v-card-title>
      <v-card-text>
        <inventorys-form
          :title="$t('new', { name: $tc('inventory') })"
          :inventory="inventory"
          @validated="updateInventory"
          @cancel="
            dialog = false;
            $emit('update');
          "
        ></inventorys-form
      ></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InventoryEdit",
  components: { InventorysForm: () => import("@/components/inventory/Form") },
  mounted() {
    this.fetchInventory();
  },
  data() {
    return {
      inventorySaved: false,
      validationErrors: [],
      inventory: {},
      dialog: false,
    };
  },
  methods: {
    ...mapActions("inventory", ["editInventory", "getInventory"]),
    fetchInventory() {
      this.getInventory(this.$route.params.id).then((response) => {
        this.inventory = response;
        //console.log(this.inventory);
      });
    },
    updateInventory() {
      //console.log("update");
      let formData = new FormData();
      Object.keys(this.inventory).forEach((key) => {
        if (this.inventory[key]) formData.append(key, this.inventory[key]);
      });
      this.editInventory({
        product_id: this.$route.params.id,
        inventory: formData,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("inventory_edit_success"), "", "");
        }
        //this.$router.back();
        this.$emit("update");
        this.dialog = false;
      });
    },
  },
};
</script>